/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'twitter': {
    width: 16,
    height: 13,
    viewBox: '0 0 16 13',
    data: '<path pid="0" d="M5.032 13c6.037 0 9.34-5.002 9.34-9.34 0-.142 0-.283-.01-.424A6.681 6.681 0 0016 1.537a6.55 6.55 0 01-1.885.516A3.293 3.293 0 0015.558.238a6.599 6.599 0 01-2.085.797 3.285 3.285 0 00-5.594 2.993A9.314 9.314 0 011.114.598 3.285 3.285 0 002.13 4.981 3.27 3.27 0 01.64 4.57v.041a3.283 3.283 0 002.634 3.218c-.484.132-.99.151-1.483.056a3.286 3.286 0 003.067 2.28A6.58 6.58 0 010 11.526a9.29 9.29 0 005.032 1.471" _fill="#9DCBC2" fill-rule="evenodd"/>'
  }
})
